import { useState, React } from "react";
import { useHistory, Redirect, Link } from 'react-router-dom'

import {logout, saveUser} from "utils/session"

// Soft UI Dashboard React components
import {resetSendMailApi, apiPostUnsecure} from "utils/api"

import validator from 'validator'

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import curved9 from "assets/images/curved-images/curved-6.jpg";
import CoverLayout from "../components/CoverLayout";
import {progressDialog, alertDialog} from "utils/diloag"

function Forgot() {

  const history = useHistory();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const [progressTitle, setProgressTitle] = useState('');
  const [showAlertTitle, setShowAlertTitle] = useState('');

  // logout();

  function showProgress(title) {
    setProgressTitle(title)
  }

  function hideProgress() {
    setProgressTitle('')
  }

  function onAlertOk() {
    setShowAlertTitle('');
    history.push('/dashboard');
  }
  
  async function verify() {

    if(email.trim() === "") {
      setError('Enter registered email')
      return
    }
    if(!validator.isEmail(email)) {
      setError('Invalid email')
      return
    }

    setError('');
    showProgress('Verifying email!');
    
    const data = { email }
    
    apiPostUnsecure(resetSendMailApi, data,
      (response) => {
        hideProgress();
        setShowAlertTitle(response.msg);
     },
     (errorMsg) => {
        hideProgress();
        setError(errorMsg);
     }
   )

  }

  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  // console.log('screen login');

  return (
    <CoverLayout
      title="Welcome to MI Robotic!"
      description="Enter your email to reset password for your account"
      image={curved9}
    >
      {progressDialog(progressTitle)}
      {alertDialog(false, "Email Verified!.", showAlertTitle, onAlertOk, () => {})}
      <SuiBox component="form" role="form">
        <SuiBox mb={2}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SuiTypography>
          </SuiBox>
          <SuiInput type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </SuiBox>
        <SuiBox mt={3} textAlign="center">
          <SuiTypography mt={3} variant="button" fontWeight="regular" textColor="error" textAlign="center">
            {error}
          </SuiTypography>
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          <SuiButton variant="gradient" buttonColor="info" fullWidth onClick={() => verify()}>
            Verify 
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </CoverLayout>
  );
}

export default Forgot;
