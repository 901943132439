import { useEffect, useState, React, useRef } from "react";

import { Stack, Box, styled, Paper, Typography, Button, CardContent, IconButton, Tooltip, Avatar } from '@mui/material';

import PageLayout from "../../examples/LayoutContainers/PageLayout";
import curved9 from "assets/images/curved-images/curved-6.jpg";
import CoverLayout from "../../examples/CoverLayout";


function ThnkYou() {

  return (
    <CoverLayout
      title="Thank you for using MI Robotic services!"
      description="You can close this tab now."
      image={curved9}
    />
  );
}

export default ThnkYou;
