import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import imgMeetingList from "assets/screens/meeting-list.png";
import imgMeetingCreate from "assets/screens/meeting-create.png";
import imgDashboard from "assets/screens/dashboard.png";
import imgUserList from "assets/screens/user-list.png";
import imgZoomConnect from "assets/screens/zoom-connect.png";
import appLogin from "assets/screens/app/login.png";
import appHome from "assets/screens/app/meetings.png";
import appMeetingList from "assets/screens/app/meeting-list.png";
import appScan from "assets/screens/app/scan-qr.png";
import appMeetingStart from "assets/screens/app/meeting-start.png";
import imgDisconnect from "assets/screens/zoom-disconnect.png";

import Img1 from 'assets/screens/s1.png'
import Img2 from 'assets/screens/s2.png'
import Img3 from 'assets/screens/s3.png'
import Img4 from 'assets/screens/s4.png'
import Img5 from 'assets/screens/s5.png'
import Img6 from 'assets/screens/s6.png'
import Img7 from 'assets/screens/s7.png'
import Img8 from 'assets/screens/s8.png'


const imgWidth = 600

export const content = () => {
    

    return (
        <Card className="h-100">
              <SuiBox py={2} px={4}>
                <SuiTypography mt={2} variant="h4" fontWeight="medium" textTransform="capitalize">
                  1. MI Robotic on Web
                </SuiTypography>
                <SuiTypography mt={2} variant="h5" fontWeight="medium" textTransform="capitalize">
                  Connect Zoom account
                </SuiTypography>
                <SuiTypography  ml={3} align='justify' fontWeight='light' variant='h6' >
                  <p>To access your Meeting on MI Robotic, you need to connect your Zoom account</p>
                  <p>Open profile and click on <b>Connect Zoom</b>. you will be redirected to Zoom for login and give access of your account to MI Robotic</p>
                  <img
                      className="img"
                      width={imgWidth}
                      src={imgZoomConnect}
                      alt="Create meeting"
                    />
                </SuiTypography>

                <SuiTypography mt={2} variant="h5" fontWeight="medium" textTransform="capitalize">
                  Meetings
                </SuiTypography>
                <SuiTypography  ml={3} align='justify' fontWeight='light' variant='h6' >
                  <p>In meetings screen, you can see all meeting created on robot.zconnect.tech,</p>
                  <p>You can create qr code for meeting, see complete meeting details or delete meetings</p>
                  <img
                      className="img"
                      width={imgWidth}
                      src={Img1}
                      alt="Create meeting"
                    />
                </SuiTypography>

                <SuiTypography mt={2} variant="h5" fontWeight="medium" textTransform="capitalize">
                  Create Meeting
                </SuiTypography>
                <SuiTypography  ml={3} align='justify' fontWeight='light' variant='h6' >
                  <p>You can create meeting by pressing create meeting button in meetings screen</p>
                  <p>You need to select meeting location, these locations will be fetched from temi, Enter Resident name, Caller name, Contact No., Date, Time and Duration in minutes</p>
                  <img
                      className="img"
                      width={imgWidth}
                      src={Img4}
                      alt="Create meeting"
                    />
                  <p>Email is an optional, but if email is provided, Caller will receive the meeting invitation on email with contains meeting summary and link to join meeting</p>
                  <img
                      className="img"
                      width={imgWidth}
                      src={Img3}
                      alt="Create meeting"
                    />
                </SuiTypography>

                <SuiTypography mt={2} variant="h5" fontWeight="medium" textTransform="capitalize">
                  Join Meeting
                </SuiTypography>
                <SuiTypography  ml={3} align='justify' fontWeight='light' variant='h6' >
                  <p>Caller can join meeting by just opening join meeting url.</p>
                  <p>Email is an optional, but if email is provided, Caller will receive the meeting invitation on email with contains meeting summary and link to join meeting</p>
                  <img
                      className="img"
                      width={imgWidth}
                      src={Img8}
                      alt="Create meeting"
                    />
                </SuiTypography>

                <SuiTypography mt={2} variant="h5" fontWeight="medium" textTransform="capitalize">
                  Meeting Details
                </SuiTypography>
                <SuiTypography  ml={3} align='justify' fontWeight='light' variant='h6' >
                  <p>In meetings scree, this screen will open when you click on details button for the meeting</p>
                  <p>Here you will fine complete meeting details also you can join meeting, share meeting details and show or download meeting qr code to join meeting</p>
                  <img
                      className="img"
                      width={imgWidth}
                      src={Img7}
                      alt="Create meeting"
                    />
                </SuiTypography>

                <SuiTypography mt={2} variant="h5" fontWeight="medium" textTransform="capitalize">
                  Disconnect / Remove Zoom account
                </SuiTypography>
                <SuiTypography  ml={3} align='justify' fontWeight='light' variant='h6' >
                  <p>If you want to change Zoom account or remove Zoom account from MI Robotic, then just got to profile and click on disconnect Zoom button as shown in image below.</p>
                  <p>Note: Once you disconnected your Zoom account, It will also delete all meetings created on MI Robotic. </p>
                  <img
                      className="img"
                      width={imgWidth}
                      src={imgDisconnect}
                      alt="Disconnect Zoom"
                    />
                </SuiTypography>
                <SuiTypography mt={2} variant="h4" fontWeight="medium" textTransform="capitalize">
                  2. MI Robotic App on Robo Temi
                </SuiTypography>
                <SuiTypography mt={2} variant="h5" fontWeight="medium" textTransform="capitalize">
                  Meeting
                </SuiTypography>
                <SuiTypography  ml={3} align='justify' fontWeight='light' variant='h6' >
                  <p>You can see all meeting created on robot.mirobotic.tech, but you need to make sure that to login with same account as on web.</p>
                  <p>Once meeting is created or deleted, Temi will auto update them</p>
                  <p>Also, Once app started on Temi, It will auto sync all meetings and update all saved locations on server which is used to select meeting location in web while creating meetings</p>
                  <img
                      className="img"
                      width={imgWidth}
                      src={Img5}
                      alt="Create meeting"
                    />
                </SuiTypography>
                <SuiTypography mt={2} variant="h5" fontWeight="medium" textTransform="capitalize">
                  Start Meeting
                </SuiTypography>
                <SuiTypography  ml={3} align='justify' fontWeight='light' variant='h6' >
                  <p>On meeting time, Temi will automatically navigate to the meeting location and once reached there it will start meeting automatically.</p>
                  <p>You can adjust Temi tablet up and down by pressing on screen buttons in meeting screen</p>
                  <p>In meeting screen you can allow members from waiting list, see all meeting participants, djust Temi tablet up and down by pressing on screen buttons</p>
                  <p>On meeting End time, temi will auto end meeting and go back to the home location</p>
                  <img
                      className="img"
                      width={imgWidth}
                      src={Img6}
                      alt="Create meeting"
                    />
                </SuiTypography>
              </SuiBox>
          </Card>
        )
}

export default content;