// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useState, useEffect, useRef } from "react";
import { useHistory, Redirect, Link } from 'react-router-dom'
import { useForm, Controller } from "react-hook-form";

import Select, {StylesConfig} from 'react-select'
import makeAnimated from 'react-select/animated';
import moment from 'moment';
import { ErrorMessage } from '@hookform/error-message';

import {Alert, AlertTitle, Typography} from "@mui/material";
import {progressDialog, RecurrenceModal, RecurrenceModal2} from "utils/diloag"
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import ArrowBack from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { Stack, Box, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import {isAuthenticated, getUser, getUserEmail} from "utils/session" 

import Footer from "examples/Footer";
import Table from "examples/Table";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import Divider from "@mui/material/Divider";
import {getDateAfterMonth, getDateNow, getTimeNow, getTimeZone, dateToServerFormat, checkIfJson, dateToShowFormat} from "utils/ext"
import {meetingsApi, apiPostSecure, locationsApi, apiCallSecureGet} from "utils/api"
import { stat } from "fs";

const isValidEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

function showErrorAlert(msg) {
  let view = null;

  if(msg) {
    view = (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
         {msg}
      </Alert>
    )
  }

  return view;
}

function showSuccessAlert(msg) {
  let view = null;

  if(msg) {
    view = (
      <Alert severity="success">
        <AlertTitle>Success!</AlertTitle>
         {msg}
      </Alert>
    )
  }

  return view;
}

function generatePassword(length) {
  let result           = '';
  const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  // console.log('password', result);
  return result;
}

function ZoomMeetings() {

  if(!isAuthenticated()) {
    return <Redirect to='/authentication/sign-in'  />
  }

  const history = useHistory();

  const [selectedOption, setSelectedOption] = useState([]);
  const [loadUsers, setLoadUsers] = useState(true);
  const [members, setMembers] = useState([]);

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [progress, setProgress] = useState('');
  const [defaultUser, setDefaultUser] = useState(null);

  
  const [meetingDate, setMeetingDate] = useState(getDateNow());
  const [meetingTime, setMeetingTime] = useState(getTimeNow());

  const [residentName, setResidentName] = useState('')
  const [residentBed, setResidentBed] = useState('')
  const [familyName, setFamilyName] = useState('')
  const [contactNo, setContactNo] = useState('')
  const [duration, setDuration] = useState('')
  const [agenda, setAgenda] = useState('')
  const [email, setEmail] = useState('')

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  
  function showError(msg) {
    setError(msg);
    setTimeout( () => {setError('')}, 3000);
  }


  const styles = {
    control: base => ({
      ...base,
      fontSize: "14px"
    }),
    menu: base => ({
      ...base,
      fontSize: "14px"
    })
  };

  const loadMembers = async () => {
    // console.log('loadMembers');
    apiCallSecureGet(locationsApi,
    (response) => {
        const users = []
        response.list.map((locaiton) => {
          users.push(
              {
                value: locaiton,  label: locaiton
              }
            )
          }
        );  
        // setDefaultUser(users[0])
        setMembers(users)

    },
    (errorMsg) => {
        setLoadUsers(false);
        showError(errorMsg||'Error');
        // console.log('ui error', errorMsg||'Error');
      }
    )
  }

  useEffect(() => {
    setLoadUsers(false);
    loadMembers();
  }, [loadUsers])

  const animatedComponents = makeAnimated();

  function showMessage(msg) {
    setMessage(msg);
    setTimeout( () => {setMessage('')}, 3000);
  }


  // console.log('TZ > ',timezoneOffset);

  const createMeeting = (data) => {

    console.log('selectedOption', selectedOption);
    console.log('residentName', residentName);
    console.log('familyName', familyName);
    console.log('contactNo', contactNo);
    console.log('duration', duration);
    console.log('meetingDate', meetingDate);
    console.log('meetingTime', meetingTime);
    console.log('agenda', agenda);
    
    // console.log('create meeting', selectedOption.length);

    if(selectedOption.length < 1) {
      showError('Meeting Start location required!')
      return
    }

    if(residentName.trim() === "") {
      showError('Resident name required!')
      return
    }
    if(familyName.trim() === "") {
      showError('Family name required!')
      return
    }

    if(email) {
      if (!isValidEmail(email)) {
        showError('Invalid email!')
        return
      }
    }

    if(contactNo.trim() === "") {
      showError('Contact number required!')
      return
    }

    if(duration.trim() === "") {
      showError('Enter meeting duration in minutes')
      return
    }

    if(meetingDate.trim() === "") {
      showError('Enter meeting date')
      return
    }

    if(meetingTime.trim() === "") {
      showError('Enter meeting time')
      return
    }

    const tz = moment.tz.guess();

    console.log('tz', tz);
    const type = 2;

    const body = {
        'username': getUserEmail(),
        'action': 'add',
        'topic': selectedOption?.value, 
        'type': type,
        'start_time': dateToServerFormat(meetingDate, meetingTime),
        'duration': duration,
        'timezone': "UTC",
        'password': generatePassword(8),
        'agenda': agenda,
        'resident_bed': residentBed,
        'resident_name': residentName,
        'family_name': familyName,
        'contact_no': contactNo,
        'email': email,
        'recurrence': null,
    }

    console.log('body', body);

    setProgress('Creating Meeting')

    apiPostSecure(meetingsApi, body, 
      (response) => {
        setProgress('');
          if(response.code === 200) {
            reset({
              resident_name: '',
              email: '',
              agenda: '',
              family_name: '',
              contact_no: '',
              duration: '',
              resident_bed: '',
              date: getDateNow(),
              time: getTimeNow(),
            })
            setResidentName('');
            setResidentBed(''); 
            setFamilyName('');
            setContactNo('');
            setDuration('');
            setAgenda(''); 
            setEmail('')
            setMeetingDate(getDateNow());
            setMeetingTime(getTimeNow());
            showMessage('Meeting is created successfully')
            return
          }
          showError(response.data.message)
      },
      (err, data) => {
        if(data) {
          const msg = `${err} Time slot from ${dateToShowFormat(data.start_time)} to ${dateToShowFormat(data.end_time)} is not available`;
          showError(msg)
        }else {
          showError(err)
        }
        console.log('data', data);
        console.log('Create meeting Error: ', err);
        setProgress('');
      })
  }

  // console.log('getTimeNow', date, time);
  // setTime(timeNow)

  // console.log('members', members.length);

  function onBack() {
    // console.log('on back');
    history.goBack();
  }

  const addView = (
    <Card className="h-100">
          <SuiBox pt={2} px={2}>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center">
              <SuiBox display="flex" flexDirection="row">
                <Button variant="text" fontWeight="medium" textColor="text" onClick={() => onBack()}>
                  <ArrowBack color="secondary" />
                </Button>
                <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Create Meeting
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox opacity={0.3}>
              <Divider />
            </SuiBox>
            <form id="hook-form" onSubmit={handleSubmit(createMeeting)}>
              <Grid container spacing={6}>
               <Grid item xs={12} md={6} xl={3}>
                  <SuiBox >
                    <SuiBox  ml={0.5}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">                        
                        Starting location
                      </SuiTypography>
                      <Select
                          styles={styles}
                          onChange={setSelectedOption}
                          placeholder='Search or select location'
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          options={members}
                          defaultValue={defaultUser}
                          
                        />
                    </SuiBox>
                    {errors.location && <Typography variant="subtitle2" color='orange'>{errors.location.message}</Typography>}
                  </SuiBox>
                </Grid>   
                <Grid item xs={12} md={6} xl={3}>
                  <SuiBox 
                      sx={{widows: '100%'}}>
                    <SuiBox  ml={0.5}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Resident name
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      type='phone'
                      fullWidth
                      value={residentName}
                      autoComplete="off"
                      onChange = {(e) =>  {
                        setResidentName( e.target.value)
                      }}
                    />
                     {errors.resident_name && <Typography variant="subtitle2" color='orange'>{errors.resident_name.message}</Typography>}
                  </SuiBox>          
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <SuiBox >
                    <SuiBox  ml={0.5}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Caller name
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      type='phone'
                      fullWidth
                      value={familyName}
                      autoComplete="off"
                      onChange = {(e) =>  setFamilyName(e.target.value)}
                    />
                     {errors.family_name && <Typography variant="subtitle2" color='orange'>{errors.family_name.message}</Typography>}
                  </SuiBox>          
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <SuiBox >
                    <SuiBox  ml={0.5}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Email (optional)
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      type='email'
                      fullWidth
                      value={email}
                      autoComplete="off"
                      onChange = {(e) =>  setEmail(e.target.value)}
                    />
                     {errors.email && <Typography variant="subtitle2" color='orange'>{errors.email.message}</Typography>}
                  </SuiBox>          
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                  <SuiBox >
                    <SuiBox  ml={0.5}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Contact No.
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      type='number'
                      fullWidth
                      autoComplete="off"
                      value={contactNo}
                      onChange = {(e) =>  setContactNo(e.target.value)}
                    />
                    {errors.contact_no && <Typography variant="subtitle2" color='orange'>{errors.contact_no.message}</Typography>}
                  </SuiBox>          
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                  <SuiBox >
                    <SuiBox  ml={0.5}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Resident bed (optional)
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      type='phone'
                      fullWidth
                      value={residentBed}
                      autoComplete="off"
                      onChange = {(e) =>   setResidentBed(e.target.value)}
                    />
                  </SuiBox>          
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                  <SuiBox >
                    <SuiBox ml={0.5}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Date
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      type='date'
                      fullWidth
                      autoComplete="off"
                      value={meetingDate}
                      onChange = {(e) =>  setMeetingDate(e.target.value)}
                    />
                    {errors.date && <Typography variant="subtitle2" color='orange'>{errors.date.message}</Typography>}
                  </SuiBox>          
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                  <SuiBox >
                    <SuiBox  ml={0.5}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Time 
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      type='time'
                      fullWidth
                      autoComplete="off"
                      value={meetingTime}
                      onChange = {(e) =>  setMeetingTime(e.target.value)}
                    />
                    {errors.time && <Typography variant="subtitle2" color='orange'>{errors.time.message}</Typography>}
                  </SuiBox>
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                  <SuiBox >
                    <SuiBox  ml={0.5}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Duration in minutes
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      type='number'
                      fullWidth
                      value={duration}
                      autoComplete="off"
                      onChange = {(e) => setDuration(e.target.value)}
                    />
                    {errors.duration && <Typography variant="subtitle2" color='orange'>{errors.duration.message}</Typography>}
                  </SuiBox>          
                </Grid>
                <Grid item xs={12} md={12} xl={9}>
                  <SuiBox >
                    <SuiBox  ml={0.5}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Agenda (optional)
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                        type='phone'
                        fullWidth
                        value={agenda}
                        autoComplete="off"
                        onChange = {(e) => setAgenda(e.target.value)}
                      />
                  </SuiBox>          
                </Grid>
                <Grid item xs={12} md={3} xl={3}></Grid>
                <Grid item xs={12} md={12} xl={12}>
                <ErrorMessage
                                    errors={errors}
                                    name="singleErrorInput"
                                    render={({ message }) => <p>{message}</p>}
                                />
                </Grid>
              </Grid>
            </form>
            
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" py={3}>
              <SuiBox display="flex" flexDirection="row" >
                <SuiButton variant="gradient" buttonColor="secondary"  onClick={() => onBack()} >
                  cancel
                </SuiButton>
                <SuiBox pl={2}>
                  <Button  form="hook-form" type="submit" variant="contained" >
                    Create Meeting
                  </Button>
                </SuiBox>
              </SuiBox>
          </SuiBox>
        </SuiBox>
      </Card>
      )
    
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          {showSuccessAlert(message)}
          {showErrorAlert(error)}
          {progressDialog(progress)}
          {addView}
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ZoomMeetings;
