import * as React from 'react';
import { useForm, Controller,  } from "react-hook-form";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SuiInput from "components/SuiInput";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import * as Yup from 'yup';
import { Formik } from 'formik';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import QRCode from 'qrcode.react';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { saveAs } from 'file-saver';
import {getQrId} from './session'
import { TextField } from '@mui/material';
import {getDateTimeNow, isBeforeDate, isBeforeToday, getJoinUrl} from './ext'
import { exportComponentAsPNG } from 'react-component-export-image';
import SuiButton from 'components/SuiButton';
import { link, stat } from 'fs';

const defY = 1;

const Alert = React.forwardRef(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  }
);

export const alertDialog = (showCancel, title, message, onOkey, onCancel) => {

  // console.log('alert');

  const open = message.trim() !== "";

  let actions = null;
  if(showCancel) {
    actions = (
      <DialogActions>
        <Button onClick={() => {onCancel()}}>Cancel</Button>
        <Button onClick={() => {onOkey()}} >
          Okay
        </Button>
    </DialogActions>
    )
  }else {
    actions = (
      <DialogActions>
        <Button onClick={() => {onOkey()}} >
          Okay
        </Button>
    </DialogActions>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={() => {onCancel()}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      {actions}
    </Dialog>
  );
}

export const qrDialog = (user, onClose) => {

  // console.log('user', user);
  const show = user !== null;
  let qr = null;
  let name = null;
  if(user !== null) {
    const data = {
      'code': base64_encode(user.accountId)
    };
    const text = JSON.stringify(data);
    // console.log('encoded', qr);
    name = `${user.first_name} ${user.last_name}`;
    qr = (<QRCode value={text} size='250' />);
  }
  
  const donwloadQr = () => {
    if(user !== null) {
      const canvas = document.querySelector('.HpQrcode > canvas');
      canvas?.toBlob(function(blob) {
        saveAs(blob, `${user.first_name}_${user.last_name}.png`);
      });
    }
  }

  return (
    <Dialog
      open={show}
      onClose={() => {onClose()}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        QR code for glass {name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="HpQrcode">
           {qr}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {onClose()}}>Close</Button>
        <Button onClick={() => {donwloadQr()}} >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const myQrCode = () => {
  
  const qrData = getQrId();

  // console.log('qrData', qrData);

  // const data = {
  //   'code': userId
  // };

  // const text = base64_encode(JSON.stringify(data));
  // console.log('encoded', qrData);
  return (<QRCode bgColor={'transparent'} value={qrData} size='170' />);
}

export const progressDialog = (title) => {
  const open = (title != null && title.trim() !== '');

  return (
    <Dialog
        open={open}
        keepMounted
        onClose={() => {}}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Box justifyContent="center" sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
  );
}



/* error, warning, info, success */
export function showToastMessage(open, type, msg, handleClose) {
    return (
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
         {msg}
        </Alert>
      </Snackbar>
    );
  }

  export const ShowToast = ({show, type, msg, close}) => {
    return (
      <Snackbar 
      anchorOrigin={ 
        {
          vertical: 'top',
          horizontal: 'right',
        }
       } open={show} autoHideDuration={3000} onClose={close}>
        <Alert onClose={close} severity={type} sx={{ width: '100%' }}>
         {msg.current}
        </Alert>
      </Snackbar>
    );
  }

  export const MettingQrDialog = ({meeting, setMeeting}) => {

    console.log('MettingQrDialog', meeting);
    let show = false;
    let qr = null;
    let name = null;

    if(meeting !== null) {
      show = true;
      name = `${meeting.topic}`;
      const link = getJoinUrl(meeting.id, meeting.password);
      console.log('link', link)
      qr = (<QRCode value={link} size='250' />);
    }
    
    const donwloadQr = () => {
      if(meeting !== null) {
        const canvas = document.querySelector('.HpQrcode > canvas');
        canvas?.toBlob(function(blob) {
          saveAs(blob, `${meeting.id}_${meeting.topic}.png`);
        });
      }
    }
  
    return (
      <Dialog
        open={show}
        onClose={() => {setMeeting(null)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Scan QR code to join meeting {name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className="HpQrcode">
             {qr}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setMeeting(null)}}>Close</Button>
          <Button onClick={() => {donwloadQr()}} >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    );
  }  

  const getQrCode = (text) => {
    const valid = text === null || text.trim() === '';
    console.log('text', text)
    console.log('valid', valid)
    if(valid) {
      return null
    }
    return (<QRCode value={text.trim()} size='150' />);
  }

  export const NewQrDialog = ({show, setShow}) => {

    const [url, setUrl] = React.useState(null)
    const componentRef = React.useRef();

    const closeDialog = () => {
        setShow(false);
        setUrl(null);
    }

    return (
      <Dialog
        open={show}
        onClose={() => {closeDialog()}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: "400px",
            height: "500px",
          }}}
      >
        <DialogTitle id="alert-dialog-title">
          Paste meeting url to create QR code.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className="HpQrcode">
            <SuiBox mb={2}>
              <SuiBox mb={1} ml={0.5}>
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Zoom Meeting URL
                </SuiTypography>
              </SuiBox>
                <SuiInput type="text" placeholder="Enter zoom meeting url" value={url} onChange={(e) => setUrl(e.target.value)} />
            </SuiBox>
            <Stack 
              ref={componentRef}
              justifyContent="center"
              alignItems="center">
               {getQrCode(url)}
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {closeDialog()}}>Close</Button>
          <Button onClick={() => {exportComponentAsPNG(componentRef, {fileName: getDateTimeNow()})}} >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    );
  }    

  export const DeleteRecurringMeeting = ({show, setShow, meeting, onDeleteSingle, onDeleteAll}) => {

    const closeDialog = () => {
        setShow(false);
    }

    return (
      <Dialog
        open={show}
        onClose={() => {closeDialog()}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete {meeting.current?.topic}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className="HpQrcode">
            <SuiBox mb={2}>
              <SuiBox mb={1} ml={0.5}>
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={() => {onDeleteSingle(meeting)}} >
            Delete This Occurrence
          </Button>
          <Button color='error' onClick={() => {onDeleteAll(meeting)}} >
            Delete All Occurrences
          </Button>
          <Button onClick={() => {closeDialog()}}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }    

  /*
                             <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="1"
                                    name="radio-buttons-group"
                                    onChange={handleWeek}
                                    value={week}
                                  >
                                      <FormControlLabel value="1" control={<Radio />} label="First Week" />
                                      <FormControlLabel value="2" control={<Radio />} label="Second Week" />
                                      <FormControlLabel value="3" control={<Radio />} label="Third Week" />
                                      <FormControlLabel value="4" control={<Radio />} label="Fourth Week" />
                                      <FormControlLabel value="-1" control={<Radio />} label="Last Week" />
                                  </RadioGroup>
  */
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));


  const GetCheckBox = ({checked, id, name, handleCheckBox}) => {

      if(checked) {
        return <FormControlLabel name={id} onChange={handleCheckBox} control={<Checkbox  defaultChecked />} label={name} />
      }

      return <FormControlLabel name={id} onChange={handleCheckBox} control={<Checkbox   />} label={name} />
  }

  export const RecurrenceModal = ( { show, change, state, onSave }) => {

    const handleClose = () => change(false);
  
    const [tabValue, setTabValue] = React.useState(state.current?.tab);
    const [endDate, setEndDate] = React.useState(state.current?.date_end)
    const [endTime, setEndTime] = React.useState(state.current?.time_end)
    const [week, setWeek] = React.useState(state.current?.week)
    const [weekDay, setWeekDay] = React.useState(state.current?.week_day)
    const [monthDate, setMonthDate] = React.useState(state.current?.date_month)
    const [errorMsg, setErrorMsg] = React.useState('')

    console.log('state', state.current);

    const handleChange = (event, newValue) => {
      setTabValue(newValue);
    };

    React.useEffect(() => {
      if(state.current == null) {
        return;
      }
      state.current['tab'] = tabValue;
      console.log('useEffect TAB', state.current['tab'])
    }, [tabValue])

    const a11yProps = (index) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

    const handleCheckBox = (event) => {
      // state.current?.[event.target.name] = event.target.value;
      if(state.current == null) {
        return;
      }
      state.current[event.target.name] = event.target.checked;
    }

    const handleEndDate = (event) => {
      if(state.current == null) {
        return;
      }
      state.current['date_end'] = event.target.value;
      setEndDate(event.target.value);
    }

    const handleEndTime = (event) => {
      if(state.current == null) {
        return;
      }
      state.current['time_end'] = event.target.value;
      setEndTime(event.target.value);
    }

    const handleRepeatDate = (event) => {
      if(state.current == null) {
        return;
      }
      state.current['date_month'] = event.target.value;
      setMonthDate(event.target.value);
    }

    const handleWeek = (event) => {
      if(state.current == null) {
        return;
      }
      state.current['week'] = event.target.value;
      setWeek(event.target.value);
    }

    const handleWeekDay = (event) => {
      if(state.current == null) {
        return;
      }
      state.current['week_day'] = event.target.value;
      setWeekDay(event.target.value);
    }

    const onError = (msg) => {
      setErrorMsg(msg);
      setTimeout( () => {setErrorMsg('')}, 3000);
    }

    const validate = () => {

        if(isBeforeToday(endDate)) {
          console.log('isBeforeDate');
          onError('End date for recurring meeting is before today\'s date')
          return;
        }
      
        console.log('validate', tabValue);
        if (tabValue == 2) {
            const isBefor = isBeforeDate(endDate, monthDate);
            console.log('isBeforeDate', isBefor);
            if(isBefor) {
              console.log('isBeforeDate');
              onError('End date for recurring meeting is before recurring month date')
              return;
            }
        } 

        onSave(tabValue, state);
        handleClose();
        
    }

    return (
        <Dialog 
            fullWidth
            maxWidth="sm"
            open={show} onClose={handleClose}>
                <DialogTitle>Recurrence</DialogTitle>
                    <DialogContent>
                        <Box sx={{ width: '400', height:'400' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={tabValue} onChange={handleChange} aria-label="Programming Language">
                                    <Tab label="Daily" {...a11yProps(0)} />
                                    <Tab label="Weekly" {...a11yProps(1)} />
                                    <Tab label="Monthly" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={tabValue} index={0}>
                                <SuiTypography variant='h6' color='light'>Repeats Daily</SuiTypography>
                                <FormGroup>
                                    <GetCheckBox checked={state.current?.day_2} id='day_2' name='Monday' handleCheckBox={handleCheckBox} />
                                    <GetCheckBox checked={state.current?.day_3} id='day_3' name='Tuesday' handleCheckBox={handleCheckBox} />
                                    <GetCheckBox checked={state.current?.day_4} id='day_4' name='Wednesday' handleCheckBox={handleCheckBox} />
                                    <GetCheckBox checked={state.current?.day_5} id='day_5' name='Thursday' handleCheckBox={handleCheckBox} />
                                    <GetCheckBox checked={state.current?.day_6} id='day_6' name='Friday' handleCheckBox={handleCheckBox} />
                                    <GetCheckBox checked={state.current?.day_7} id='day_7' name='Saturday' handleCheckBox={handleCheckBox} />
                                    <GetCheckBox checked={state.current?.day_1} id='day_1' name='Sunday' handleCheckBox={handleCheckBox} />
                                </FormGroup>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <FormControl>
                                <SuiTypography variant='h6' color='light'>Repeats for a selected day of first week in month</SuiTypography>
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="1"
                                    name="radio-buttons-group"
                                    onChange={handleWeekDay}
                                    value={weekDay}
                                  >
                                      <FormControlLabel value="2" control={<Radio />} label="Monday" />
                                      <FormControlLabel value="3" control={<Radio />} label="Tuesday" />
                                      <FormControlLabel value="4" control={<Radio />} label="Wednesday" />
                                      <FormControlLabel value="5" control={<Radio />} label="Thursday" />
                                      <FormControlLabel value="6" control={<Radio />} label="Friday" />
                                      <FormControlLabel value="7" control={<Radio />} label="Saturday" />
                                      <FormControlLabel value="1" control={<Radio />} label="Sunday" />
                                  </RadioGroup>
                                </FormControl>
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <SuiTypography variant='h6' color='light'>Repeats for day in every month</SuiTypography>
                                <SuiBox  ml={0.5}>
                                <SuiTypography component="label" variant="caption" fontWeight="bold">
                                  Select day in month 
                                </SuiTypography>
                                </SuiBox>
                                <SuiInput type="date" placeholder="Date" value={monthDate} onChange={handleRepeatDate} />
                            </TabPanel>
                        </Box>
                        <SuiBox >
                          <SuiBox  ml={0.5}>
                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                              Recurrence End Date 
                            </SuiTypography>
                          </SuiBox>
                          <SuiInput type="date" placeholder="Date" value={endDate} onChange={handleEndDate}  />
                          <SuiBox >
                          <SuiBox  ml={0.5}>
                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                              Recurrence End Time 
                            </SuiTypography>
                          </SuiBox>
                          <SuiInput type="time" placeholder="Time" value={endTime} onChange={handleEndTime} />
                        </SuiBox>
                        </SuiBox>
                        <SuiBox >
                          <SuiBox  ml={0.5}>
                            <Typography color='error' component="label" variant="caption" fontWeight="bold">
                               {errorMsg}
                            </Typography>
                          </SuiBox>
                        </SuiBox>                        
                    </DialogContent>
                <DialogActions>
                    <SuiButton variant="contained"  onClick={() => handleClose()}>Close</SuiButton>
                    <SuiButton  variant="gradient" buttonColor="primary"  onClick={() => validate()}>Save</SuiButton>
                </DialogActions>
      </Dialog>
    )
  }  

export default progressDialog();

export const RecurrenceModal2 = ( { show, change, state, onSave }) => {

  const handleClose = () => change(false);

  const [tabValue, setTabValue] = React.useState(state.current?.tab);
  const [endDate, setEndDate] = React.useState(state.current?.end)
  const [week, setWeek] = React.useState(state.current?.week)
  const [monthDate, setMonthDate] = React.useState(state.current?.month)

  const [repeatMonday, setRepeatMonday] = React.useState(state.current?.day_2)
  const [repeatTuesday, setRepeatTuesday] = React.useState(state.current?.day_3)
  const [repeatWednesday, setRepeatWednesday] = React.useState(state.current?.day_4)
  const [repeatThursday, setRepeatThursday] = React.useState(state.current?.day_5)
  const [repeatFriday, setRepeatFriday] = React.useState(state.current?.day_6)
  const [repeatSaturday, setRepeatSaturday] = React.useState(state.current?.day_7)
  const [repeatSunday, setRepeatSunday] = React.useState(state.current?.day_1)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  
  console.log('state', state.current);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const a11yProps = (index) => {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }

  const handleCheckBox = (event) => {
    // state.current?.[event.target.name] = event.target.value;
    if(state.current == null) {
      return;
    }

    switch(event.target.name) {
      case '1':
        console.log('sun');
        setRepeatSunday(event.target.value);
        break;
      case '2':
        console.log('mon');
        setRepeatMonday(event.target.value);
        break;
      case '3':
        console.log('tue');
        setRepeatTuesday(event.target.value);
        break;
      case '4':
        console.log('wed');
        setRepeatWednesday(event.target.value);
        break;
      case '5':
        console.log('thr');
        setRepeatThursday(event.target.value);
        break;
      case '6':
        console.log('fri');
        setRepeatFriday(event.target.value);
        break;
      case '7':
        console.log('sat');
        setRepeatSaturday(event.target.value);
        break;
    }

    state.current[event.target.name] = event.target.value;
    console.log('handleCheckBox', event.target.name, event.target.checked);
  }

  const handleEndDate = (event) => {
    if(state.current == null) {
      return;
    }
    state.current['end'] = event.target.value;
    setEndDate(event.target.value);
    console.log('handleEndDate', event.target.name, event.target.value);
  }

  const handleRepeatDate = (event) => {
    if(state.current == null) {
      return;
    }
    state.current['month'] = event.target.value;
    setMonthDate(event.target.value);
    console.log('handleRepeatDate', event.target.name, event.target.value);
  }

  const handleWeek = (event) => {
    if(state.current == null) {
      return;
    }
    state.current['week'] = event.target.value;
    setWeek(event.target.value);
    console.log('handleWeek', event.target.name, event.target.value);
  }


  return (
      <Dialog 
          fullWidth
          maxWidth="sm"
          open={show} onClose={handleClose}>
              <DialogTitle>Recurrence</DialogTitle>
                  <DialogContent>
                  <Formik
                    initialValues={{
                        day_1: state.current?.day_1,
                        day_2: state.current?.day_2,
                        day_3: state.current?.day_3,
                        day_4: state.current?.day_4,
                        day_5: state.current?.day_5,
                        day_6: state.current?.day_6,
                        day_7: state.current?.day_7,
                        week: state.current?.week,
                        date_month: state.current?.date_month,
                        date_end: state.current?.date_end,
                        submit: null
                    }}                
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                          onSave(values);
                          handleClose();                          
                        } catch (err) {
                            console.error(err);
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <Box>
                              <Box sx={{ width: '400', height:'400' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabValue} onChange={handleChange} aria-label="Programming Language">
                                        <Tab label="Daily" {...a11yProps(0)} />
                                        <Tab label="Weekly" {...a11yProps(1)} />
                                        <Tab label="Monthly" {...a11yProps(2)} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={tabValue} index={0}>
                                    <SuiTypography variant='h6' color='light'>Repeats Daily</SuiTypography>
                                    <FormGroup>
                                        <FormControlLabel name="day_2" onChange={handleCheckBox} control={<Checkbox checked={repeatMonday} />} label="Monday" />
                                        <FormControlLabel name="day_3" onChange={handleCheckBox} control={<Checkbox checked={repeatTuesday} />} label="Tuesday" />
                                        <FormControlLabel name="day_4" onChange={handleCheckBox} control={<Checkbox checked={repeatWednesday}  />} label="Wednesday" />
                                        <FormControlLabel name="day_5" onChange={handleCheckBox} control={<Checkbox checked={repeatThursday}  />} label="Thursday" />
                                        <FormControlLabel name="day_6" onChange={handleCheckBox} control={<Checkbox checked={repeatFriday} />} label="Friday" />
                                        <FormControlLabel name="day_7" onChange={handleCheckBox} control={<Checkbox checked={repeatSaturday}  />} label="Saturday" />
                                        <FormControlLabel name="day_1" onChange={handleCheckBox} control={<Checkbox checked={repeatSunday} />} label="Sunday" />
                                    </FormGroup>
                                </TabPanel>
                                <TabPanel value={tabValue} index={1}>
                                    <SuiTypography variant='h6' color='light'>Repeats for a Week in month</SuiTypography>
                                    <FormControl>
                                      <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="1"
                                        name="radio-buttons-group"
                                        onChange={handleWeek}
                                        value={week}
                                      >
                                          <FormControlLabel value="1" control={<Radio />} label="First Week" />
                                          <FormControlLabel value="2" control={<Radio />} label="Second Week" />
                                          <FormControlLabel value="3" control={<Radio />} label="Third Week" />
                                          <FormControlLabel value="4" control={<Radio />} label="Fourth Week" />
                                          <FormControlLabel value="-1" control={<Radio />} label="Last Week" />
                                      </RadioGroup>
                                    </FormControl>
                                </TabPanel>
                                <TabPanel value={tabValue} index={2}>
                                    <SuiTypography variant='h6' color='light'>Repeats for day in every month</SuiTypography>
                                    <SuiBox  ml={0.5}>
                                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                                      Select day in month 
                                    </SuiTypography>
                                    </SuiBox>
                                    <SuiInput type="date" placeholder="Date" value={monthDate} onChange={handleRepeatDate} />
                                </TabPanel>
                              </Box>
                              <SuiBox  ml={0.5}>
                                <SuiTypography component="label" variant="caption" fontWeight="bold">
                                  Recurrence End Date 
                                </SuiTypography>
                              </SuiBox>
                              <SuiInput type="date" placeholder="Date" value={endDate} onChange={handleEndDate}  />
                            </Box>
                            {errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            )}
                            <Box sx={{ mt: 2 }}>
                                    <Button
                                        disableElevation
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                    >
                                       Save
                                    </Button>
                                <SuiButton variant="contained"  onClick={() => handleClose()}>Close</SuiButton>
                                <SuiButton  variant="gradient" buttonColor="primary"  onClick={() => onSave()}>Save</SuiButton>
                            </Box>
                        </form>
                    )}
                </Formik>
          </DialogContent>
    </Dialog>
  )
}  