import { useState, React } from "react";
import { useHistory, Redirect, Link } from 'react-router-dom'
import {Card, Grid, Divider, Box} from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import BasicLayout from "layouts/docs/components/BasicLayout";
import SoftAvatar from "components/SuiAvatar";


// Images
import curved6 from "assets/images/curved-images/curved14.jpg";
import Img1 from "assets/images/img-1.jpg";
import Img2 from "assets/images/img-2.jpg";

import axios from "axios";
const imgWidth = 300


function About() {

  const policy = (
    <Card className="h-100">
          <SuiBox p={3} px={2}>
              <SuiTypography mt={2} variant="h4" fontWeight="medium" textTransform="h6">
                About Us
              </SuiTypography>
              <SuiBox opacity={0.3}>
                <Divider />
 
              </SuiBox>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={6}>
                    <img
                    width='100%'
                    src={Img1}
                    alt="Create meeting"
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <img
                    width='100%'
                    src={Img2}
                    alt="Create meeting"
                    />
                  </Grid>
                </Grid>
              </Box>
              <SuiTypography  align='justify' fontWeight='light' variant='h6' >
              
                Founded in June 2018, MI Robotic is a Singapore-headquartered developer of applied AI and robotics technology for a variety of different environments including home, school, healthcare, industrial, F&B, and retail. The distributor in Singapore for MI Robots, Niryo education robot and Ohmnilabs telepresence robot. As well as Google Glass EE2. MI Robotic is provider of AI-related customize software development and associated IT services for advanced robotics applications.
              </SuiTypography>
              <SuiTypography sx={{mt: 2}} align='justify' fontWeight='light' variant='h6' >
                MI Robotic works with Chinese/Taiwanese/USA/France manufacturers for different type of robots and is the re-seller of temi. The company creates state-of-the-art robotics products that meet customers’ exacting requirements, solving robotics problems using the latest cutting edge technologies.
              </SuiTypography>
              <SuiTypography sx={{mt: 2}} align='justify' fontWeight='light' variant='h6' >
                Offering innovative products to help customers’ run their businesses more effectively and save costing, MI Robotic delivers the solutions which allow today’s advanced personal robots to integrate into all aspects of our everyday lives, both at home and in the workplace.
              </SuiTypography>
              <SuiTypography sx={{mt: 2}} align='justify' fontWeight='light' variant='h6' >
                The company’s bespoke robotics systems encompass such advanced applied technologies as AI Systematic assessments. By grading user responses by speech, text, and visual feedback, MI Robotic can deliver emotional analysis, textual analysis, grammatical evaluation, and image-to text-representation.
              </SuiTypography>
              <SuiTypography sx={{mt: 2}} align='justify' fontWeight='light' variant='h6' >
                MI Robotic also provides web development and integration and Android & iOS mobile application development to grow robot-enabled businesses, allowing early-stage entrepreneurs to transform their raw ideas into reality using cutting-edge robotic technologies.
              </SuiTypography>
              <SuiTypography sx={{mt: 2}} align='justify' fontWeight='light' variant='h6' >
              </SuiTypography>
          </SuiBox>
      </Card>
    )

  return (
    <BasicLayout
      title="ABOUT US"
      description="MI Robotic"
      image={curved6}
    >
      <Card>
        {policy}
      </Card>
    </BasicLayout>
  );
}

export default About;